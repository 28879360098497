import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export const LogoMobile = () => {
  return (
    <svg
      className="mx-auto"
      width="232"
      height="57"
      viewBox="0 0 232 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M11.9571 27.0665V56.4941H-0.160499V0.42157H27.9267V12.6479H11.9571V14.8402H27.9267V27.0665H11.9571Z"
          fill="#E8D5AA"
        />
        <path
          d="M45.1802 44.1834V0.33728H57.2978V45.111C57.2978 48.2308 56.1743 50.8447 54.0076 53.037C51.8409 55.2293 49.1927 56.3255 46.1432 56.3255H42.1307C39.0813 56.3255 36.4331 55.2293 34.2663 53.037C32.0996 50.8447 30.9761 48.1465 30.9761 45.111V0.4216H43.0937V44.2678H45.1802V44.1834Z"
          fill="#E8D5AA"
        />
        <path
          d="M72.4649 12.6479V56.4941H60.3473V11.6361C60.3473 8.51624 61.4708 5.90234 63.6375 3.71003C65.8042 1.51772 68.4524 0.42157 71.5019 0.42157H75.5144C78.5638 0.42157 81.212 1.51772 83.3788 3.71003C85.5455 5.90234 86.669 8.60056 86.669 11.6361V56.4097H74.5514V12.6479H72.4649Z"
          fill="#E8D5AA"
        />
        <path
          d="M119.491 0.42157L109.139 31.0296C111.225 31.8728 112.91 33.3062 114.194 35.1612C115.478 37.0162 116.12 39.1242 116.12 41.4852V56.4097H104.003V42.497H101.916V56.4941H89.7987V0.42157H101.916V14.6716L106.731 0.42157H119.491Z"
          fill="#E8D5AA"
        />
        <path
          d="M154.078 12.4793H146.856C145.732 12.4793 144.689 12.9009 143.887 13.7441C143.084 14.5872 142.683 15.5991 142.683 16.7796V40.0517C142.683 44.605 141.078 48.3994 137.948 51.6035C134.818 54.8077 130.966 56.4097 126.473 56.4097H119.17V44.352H126.473C127.596 44.352 128.639 43.9304 129.442 43.0872C130.244 42.2441 130.645 41.2322 130.645 40.0517V16.7796C130.645 12.2263 132.25 8.43192 135.38 5.22778C138.51 2.02364 142.362 0.42157 146.856 0.42157H154.158V12.4793H154.078Z"
          fill="#E8D5AA"
        />
        <path
          d="M157.128 56.4097V0.42157H169.245V56.4941H157.128V56.4097Z"
          fill="#E8D5AA"
        />
        <path
          d="M187.542 56.4097H183.53C180.48 56.4097 177.832 55.3136 175.665 53.1213C173.498 50.929 172.375 48.2307 172.375 45.1952V11.6361C172.375 8.51624 173.498 5.90234 175.665 3.71003C177.832 1.51772 180.48 0.42157 183.53 0.42157H187.542C190.591 0.42157 193.24 1.51772 195.406 3.71003C197.573 5.90234 198.697 8.60056 198.697 11.6361V45.1109C198.697 48.2307 197.573 50.8446 195.406 53.037C193.159 55.3136 190.591 56.4097 187.542 56.4097ZM186.579 44.1834V12.6479H184.493V44.1834H186.579Z"
          fill="#E8D5AA"
        />
        <path
          d="M213.864 12.6479V56.4941H201.746V11.6361C201.746 8.51624 202.87 5.90234 205.036 3.71003C207.203 1.51772 209.851 0.42157 212.901 0.42157H216.913C219.963 0.42157 222.611 1.51772 224.778 3.71003C226.944 5.90234 228.068 8.60056 228.068 11.6361V56.4097H215.95V12.6479H213.864Z"
          fill="#E8D5AA"
        />
        <path
          d="M15.8893 27.0665V56.4941H3.77171V0.42157H31.8589V12.6479H15.8893V14.8402H31.8589V27.0665H15.8893Z"
          fill="#E0903A"
        />
        <path
          d="M49.1124 44.1834V0.33728H61.23V45.111C61.23 48.2308 60.1065 50.8447 57.9398 53.037C55.7731 55.2293 53.1249 56.3255 50.0754 56.3255H46.063C43.0135 56.3255 40.3653 55.2293 38.1985 53.037C36.0318 50.8447 34.9083 48.1465 34.9083 45.111V0.4216H47.0259V44.2678H49.1124V44.1834Z"
          fill="#E0903A"
        />
        <path
          d="M76.3971 12.6479V56.4941H64.2795V11.6361C64.2795 8.51624 65.403 5.90234 67.5697 3.71003C69.7364 1.51772 72.3846 0.42157 75.4341 0.42157H79.4466C82.496 0.42157 85.1442 1.51772 87.311 3.71003C89.4777 5.90234 90.6012 8.60056 90.6012 11.6361V56.4097H78.4836V12.6479H76.3971Z"
          fill="#E0903A"
        />
        <path
          d="M123.423 0.42157L113.071 31.1139C115.157 31.9571 116.843 33.3905 118.127 35.2455C119.411 37.1006 120.053 39.2086 120.053 41.5695V56.4941H107.935V42.497H105.848V56.4941H93.7309V0.42157H105.848V14.6716L110.663 0.42157H123.423Z"
          fill="#E0903A"
        />
        <path
          d="M158.01 12.4793H150.708C149.584 12.4793 148.541 12.9009 147.739 13.7441C146.936 14.5872 146.535 15.5991 146.535 16.7796V40.0517C146.535 44.605 144.93 48.3994 141.8 51.6035C138.67 54.8077 134.818 56.4097 130.324 56.4097H123.022V44.352H130.324C131.448 44.352 132.491 43.9304 133.294 43.0872C134.096 42.2441 134.497 41.2322 134.497 40.0517V16.7796C134.497 12.2263 136.102 8.43192 139.232 5.22778C142.362 2.02364 146.214 0.42157 150.708 0.42157H158.01V12.4793Z"
          fill="#E0903A"
        />
        <path
          d="M161.06 56.4097V0.42157H173.177V56.4941H161.06V56.4097Z"
          fill="#E0903A"
        />
        <path
          d="M191.474 56.4097H187.462C184.412 56.4097 181.764 55.3136 179.597 53.1213C177.431 50.929 176.307 48.2307 176.307 45.1952V11.6361C176.307 8.51624 177.431 5.90234 179.597 3.71003C181.764 1.51772 184.412 0.42157 187.462 0.42157H191.474C194.524 0.42157 197.172 1.51772 199.339 3.71003C201.505 5.90234 202.629 8.60056 202.629 11.6361V45.1109C202.629 48.2307 201.505 50.8446 199.339 53.037C197.172 55.3136 194.524 56.4097 191.474 56.4097ZM190.511 44.1834V12.6479H188.425V44.1834H190.511Z"
          fill="#E0903A"
        />
        <path
          d="M217.796 12.6479V56.4941H205.678V11.6361C205.678 8.51624 206.802 5.90234 208.969 3.71003C211.135 1.51772 213.783 0.42157 216.833 0.42157H220.845C223.895 0.42157 226.543 1.51772 228.71 3.71003C230.877 5.90234 232 8.60056 232 11.6361V56.4097H219.882V12.6479H217.796Z"
          fill="#E0903A"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="232" height="57" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export const LogoNavbar = () => {
  return (
    <StaticImage
      height={25}
      src="../images/logo.png"
      alt="mobile pattern"
      placeholder="tracedSVG"
      className="mr-auto"
    />
  )
}

export const Logo = () => {
  return (
    <div className="hidden transform xl:scale-125 lg:block">
      <svg
        className="lg:m-0"
        width="400"
        height="102"
        viewBox="0 0 400 102"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lg:mx-auto lg:m-0 xl:scale-100 2xl:scale-125"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M20.6157 48.4601V100.604H-0.276733V1.24707H48.1494V22.9113H20.6157V26.7959H48.1494V48.4601H20.6157Z"
            fill="#E8D5AA"
          />
          <path
            d="M77.8969 78.79V1.09766H98.7893V80.4335C98.7893 85.9616 96.8523 90.5932 93.1166 94.4778C89.3808 98.3625 84.8149 100.305 79.5572 100.305H72.6392C67.3815 100.305 62.8156 98.3625 59.0799 94.4778C55.3442 90.5932 53.4071 85.8122 53.4071 80.4335V1.24706H74.2995V78.9394H77.8969V78.79Z"
            fill="#E8D5AA"
          />
          <path
            d="M124.939 22.9113V100.604H104.047V21.1184C104.047 15.5903 105.984 10.9586 109.72 7.07399C113.456 3.18938 118.021 1.24707 123.279 1.24707H130.197C135.455 1.24707 140.021 3.18938 143.756 7.07399C147.492 10.9586 149.429 15.7397 149.429 21.1184V100.454H128.537V22.9113H124.939Z"
            fill="#E8D5AA"
          />
          <path
            d="M206.019 1.24707L188.17 55.4823C191.768 56.9764 194.673 59.5163 196.887 62.8033C199.101 66.0903 200.208 69.8255 200.208 74.0089V100.454H179.315V75.8018H175.718V100.604H154.825V1.24707H175.718V26.4971L184.019 1.24707H206.019Z"
            fill="#E8D5AA"
          />
          <path
            d="M265.652 22.6125H253.2C251.262 22.6125 249.464 23.3595 248.08 24.8536C246.697 26.3477 246.005 28.1406 246.005 30.2323V71.469C246.005 79.537 243.238 86.2604 237.842 91.9379C232.445 97.6154 225.804 100.454 218.056 100.454H205.465V79.0888H218.056C219.993 79.0888 221.792 78.3417 223.175 76.8477C224.559 75.3536 225.251 73.5607 225.251 71.469V30.2323C225.251 22.1642 228.018 15.4409 233.414 9.76334C238.81 4.08583 245.451 1.24707 253.2 1.24707H265.79V22.6125H265.652Z"
            fill="#E8D5AA"
          />
          <path
            d="M270.91 100.454V1.24707H291.802V100.604H270.91V100.454Z"
            fill="#E8D5AA"
          />
          <path
            d="M323.348 100.454H316.43C311.173 100.454 306.607 98.5119 302.871 94.6273C299.135 90.7426 297.198 85.9616 297.198 80.5829V21.1184C297.198 15.5903 299.135 10.9586 302.871 7.07399C306.607 3.18938 311.173 1.24707 316.43 1.24707H323.348C328.606 1.24707 333.172 3.18938 336.908 7.07399C340.643 10.9586 342.58 15.7397 342.58 21.1184V80.4335C342.58 85.9616 340.643 90.5932 336.908 94.4778C333.034 98.5119 328.606 100.454 323.348 100.454ZM321.688 78.79V22.9113H318.091V78.79H321.688Z"
            fill="#E8D5AA"
          />
          <path
            d="M368.731 22.9113V100.604H347.838V21.1184C347.838 15.5903 349.775 10.9586 353.511 7.07399C357.247 3.18938 361.813 1.24707 367.07 1.24707H373.988C379.246 1.24707 383.812 3.18938 387.548 7.07399C391.283 10.9586 393.22 15.7397 393.22 21.1184V100.454H372.328V22.9113H368.731Z"
            fill="#E8D5AA"
          />
          <path
            d="M27.3954 48.4601V100.604H6.50293V1.24707H54.9291V22.9113H27.3954V26.7959H54.9291V48.4601H27.3954Z"
            fill="#E0903A"
          />
          <path
            d="M84.6766 78.79V1.09766H105.569V80.4335C105.569 85.9616 103.632 90.5932 99.8962 94.4778C96.1605 98.3625 91.5946 100.305 86.3369 100.305H79.4189C74.1612 100.305 69.5953 98.3625 65.8595 94.4778C62.1238 90.5932 60.1868 85.8122 60.1868 80.4335V1.24706H81.0792V78.9394H84.6766V78.79Z"
            fill="#E0903A"
          />
          <path
            d="M131.719 22.9113V100.604H110.827V21.1184C110.827 15.5903 112.764 10.9586 116.499 7.07399C120.235 3.18938 124.801 1.24707 130.059 1.24707H136.977C142.234 1.24707 146.8 3.18938 150.536 7.07399C154.272 10.9586 156.209 15.7397 156.209 21.1184V100.454H135.316V22.9113H131.719Z"
            fill="#E0903A"
          />
          <path
            d="M212.798 1.24707L194.95 55.6317C198.547 57.1258 201.453 59.6657 203.667 62.9527C205.88 66.2397 206.987 69.9749 206.987 74.1583V100.604H186.095V75.8018H182.497V100.604H161.605V1.24707H182.497V26.4971L190.799 1.24707H212.798Z"
            fill="#E0903A"
          />
          <path
            d="M272.432 22.6125H259.841C257.904 22.6125 256.105 23.3595 254.722 24.8536C253.338 26.3477 252.646 28.1406 252.646 30.2323V71.469C252.646 79.537 249.879 86.2604 244.483 91.9379C239.087 97.6154 232.446 100.454 224.697 100.454H212.107V79.0888H224.697C226.634 79.0888 228.433 78.3417 229.817 76.8477C231.2 75.3536 231.892 73.5607 231.892 71.469V30.2323C231.892 22.1642 234.659 15.4409 240.055 9.76334C245.451 4.08583 252.093 1.24707 259.841 1.24707H272.432V22.6125Z"
            fill="#E0903A"
          />
          <path
            d="M277.689 100.454V1.24707H298.582V100.604H277.689V100.454Z"
            fill="#E0903A"
          />
          <path
            d="M330.128 100.454H323.21C317.952 100.454 313.386 98.5119 309.651 94.6273C305.915 90.7426 303.978 85.9616 303.978 80.5829V21.1184C303.978 15.5903 305.915 10.9586 309.651 7.07399C313.386 3.18938 317.952 1.24707 323.21 1.24707H330.128C335.386 1.24707 339.952 3.18938 343.687 7.07399C347.423 10.9586 349.36 15.7397 349.36 21.1184V80.4335C349.36 85.9616 347.423 90.5932 343.687 94.4778C339.952 98.5119 335.386 100.454 330.128 100.454ZM328.468 78.79V22.9113H324.87V78.79H328.468Z"
            fill="#E0903A"
          />
          <path
            d="M375.51 22.9113V100.604H354.618V21.1184C354.618 15.5903 356.555 10.9586 360.291 7.07399C364.026 3.18938 368.592 1.24707 373.85 1.24707H380.768C386.026 1.24707 390.592 3.18938 394.327 7.07399C398.063 10.9586 400 15.7397 400 21.1184V100.454H379.108V22.9113H375.51Z"
            fill="#E0903A"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="400"
              height="101"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export const LogoFooter = () => (
  <Link className="strong ml-2" aria-label="button to home" to="/">
    <svg
      width="66"
      height="13"
      viewBox="0 0 66 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=" "
    >
      <path
        d="M3.58872 6.17263V12.8842H0.089241V0.0957031H8.20062V2.88416H3.58872V3.38416H8.20062V6.17263H3.58872Z"
        fill="#E0903A"
      />
      <path
        d="M13.1833 10.0767V0.0766602H16.6828V10.2882C16.6828 10.9997 16.3583 11.5959 15.7326 12.0959C15.1069 12.5959 14.3421 12.8459 13.4614 12.8459H12.3027C11.422 12.8459 10.6572 12.5959 10.0315 12.0959C9.40574 11.5959 9.08128 10.9805 9.08128 10.2882V0.0958909H12.5808V10.0959H13.1833V10.0767Z"
        fill="#E0903A"
      />
      <path
        d="M21.0629 2.88416V12.8842H17.5635V2.6534C17.5635 1.94186 17.8879 1.3457 18.5137 0.845703C19.1394 0.345703 19.9042 0.0957031 20.7848 0.0957031H21.9436C22.8243 0.0957031 23.5891 0.345703 24.2148 0.845703C24.8405 1.3457 25.165 1.96109 25.165 2.6534V12.8649H21.6655V2.88416H21.0629Z"
        fill="#E0903A"
      />
      <path
        d="M34.6437 0.0957031L31.6541 7.0957C32.2567 7.28801 32.7433 7.61493 33.1142 8.03801C33.485 8.46109 33.6704 8.94186 33.6704 9.48032V12.8842H30.1709V9.69186H29.5683V12.8842H26.0688V0.0957031H29.5683V3.3457L30.9588 0.0957031H34.6437Z"
        fill="#E0903A"
      />
      <path
        d="M44.6323 2.8457H42.5234C42.1989 2.8457 41.8976 2.94186 41.6659 3.13416C41.4341 3.32647 41.3182 3.55724 41.3182 3.82647V9.13416C41.3182 10.1726 40.8547 11.038 39.9509 11.7688C39.047 12.4995 37.9346 12.8649 36.6368 12.8649H34.5278V10.1149H36.6368C36.9613 10.1149 37.2625 10.0188 37.4943 9.82647C37.726 9.63416 37.8419 9.4034 37.8419 9.13416V3.82647C37.8419 2.78801 38.3054 1.92263 39.2093 1.19186C40.1131 0.461088 41.2255 0.0957031 42.5234 0.0957031H44.6323V2.8457Z"
        fill="#E0903A"
      />
      <path
        d="M45.513 12.8649V0.0957031H49.0125V12.8842H45.513V12.8649Z"
        fill="#E0903A"
      />
      <path
        d="M54.2964 12.8649H53.1377C52.257 12.8649 51.4922 12.6149 50.8665 12.1149C50.2408 11.6149 49.9163 10.9995 49.9163 10.3072V2.6534C49.9163 1.94186 50.2408 1.3457 50.8665 0.845703C51.4922 0.345703 52.257 0.0957031 53.1377 0.0957031H54.2964C55.1771 0.0957031 55.9419 0.345703 56.5676 0.845703C57.1934 1.3457 57.5178 1.96109 57.5178 2.6534V10.288C57.5178 10.9995 57.1934 11.5957 56.5676 12.0957C55.9419 12.6149 55.1771 12.8649 54.2964 12.8649ZM54.0183 10.0765V2.88416H53.4158V10.0765H54.0183Z"
        fill="#E0903A"
      />
      <path
        d="M61.898 2.88416V12.8842H58.3985V2.6534C58.3985 1.94186 58.7229 1.3457 59.3487 0.845703C59.9744 0.345703 60.7392 0.0957031 61.6199 0.0957031H62.7786C63.6593 0.0957031 64.4241 0.345703 65.0498 0.845703C65.6756 1.3457 66 1.96109 66 2.6534V12.8649H62.5005V2.88416H61.898Z"
        fill="#E0903A"
      />
    </svg>
  </Link>
)
